import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { ICategory } from '../../interfaces/Category'
import { IProduct } from '../../interfaces/Product'
import { getCategories } from '../actions/categories'
import { getProducts } from '../actions/products'

interface ProductsReducerState {
    activeCategory: {
        name: string
        _id: string
        slug: string
        conceptId: string
    }
    activeSubCategory: {
        name: string
        _id: string
    }
    categories: ICategory[]
    getCategories: {
        loading: boolean
        error: string
    }
    products: IProduct[]
    getProducts: {
        loading: boolean
        error: string
    }
}

const initialState: ProductsReducerState = {
    activeCategory: {
        name: '',
        _id: '',
        slug: '',
        conceptId: ''
    },
    activeSubCategory: {
        name: '',
        _id: ''
    },
    categories: [],
    getCategories: {
        loading: false,
        error: ''
    },
    products: [],
    getProducts: {
        loading: false,
        error: ''
    }
}
export const productSlice = createSlice({
    initialState,
    name: 'products',
    reducers: {
        setActiveCategory: (state, action: PayloadAction<[string, string, string]>) => { 
            state.activeCategory._id = action.payload[0]
            state.activeCategory.name = action.payload[1]
            state.activeCategory.slug = action.payload[2]
        },
        setActiveSubCategory: (state, action: PayloadAction<[string, string]>) => {
            state.activeSubCategory._id = action.payload[0]
            state.activeSubCategory.name = action.payload[1]
        }
    },
    extraReducers: {
        [getCategories.pending.type]: (state) => {
            state.getCategories.loading = true
            state.getCategories.error = ''
        },
        [getCategories.fulfilled.type]: (state, action: PayloadAction<[IApi<ICategory>, string]>) => {
            state.getCategories.loading = false
            const catsID = ['64d0ac9777ae7ed436ef9f15', '64c262a3ad691786eedc615e', '64d0ac92478be1f4d2ef9e71'] //Покровский, Росы, Южный берег
            // const categories = action.payload[0].data.filter(c => c.isDeleted === false && c.isHidden === false && c.isDisabled === false)
            const categories = action.payload[0].data.filter(c => c.isDeleted === false && c.isHidden === false && c.isDisabled === false && catsID.includes(c.parentCategory))
            // console.log(categories)
            state.categories = categories
            let active = categories.find(d => d.conceptId === action.payload[1])
            const location = window.location
            // console.log(location.pathname.split('/'))
            
            const catParam = location.pathname.split('/')[3]
            if(catParam) {
                active = categories.find(d => d.slug === catParam)
            }
            // console.log(active)
            const activeSub = categories.find(c => c.parentCategory === active?._id)
            state.activeCategory = {
                name: active?.name || '',
                _id: active?._id || '',
                slug: active?.slug || '',
                conceptId: active?.conceptId || ''
            }
            state.activeSubCategory = {
                name: activeSub?.name || '',
                _id: activeSub?._id || ''
            }
        },
        [getCategories.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCategories.loading = false
            state.getCategories.error = action.error.message
        },
        [getProducts.pending.type]: (state) => {
            state.getProducts.loading = true
            state.getProducts.error = ''
        },
        [getProducts.fulfilled.type]: (state, action: PayloadAction<IApi<IProduct>>) => {
            state.getProducts.loading = false
            state.products = action.payload.data.filter(c => c.isDeleted === false && c.isHidden === false && c.isDisabled === false)
        },
        [getProducts.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getProducts.loading = false
            state.getProducts.error = action.error.message
        }
    }
})

export const productReducer = productSlice.reducer
export const productActions = productSlice.actions