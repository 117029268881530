import React, { useMemo, useState } from 'react'

import './Menu.scss'
import { useParams } from 'react-router-dom'
import { BannersCarousel } from '../../components/bannersCarousel/BannersCarousel'
import { Categories } from '../../components/categories/Categories'
import { MenuItems } from '../../components/menuItems/MenuItems'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import { MiniCart } from '../../components/miniCart/MiniCart'
import { isMobile } from 'react-device-detect'
import { MenuItemsMobile } from '../../components/menuItems/MenuItemsMobile'
import Close from '../../assets/crossBaker.png'
import { Helmet } from 'react-helmet'
export const Menu = () => {
    const { slug } = useParams()
    const { getCategories, categories, activeCategory } = useTypedSelector(
        (state) => state.productReducer
    )
    const [activeElement, setActiveElement] = useState('')
    const [openBanner, setOpenBanner] = useState(true)
    const { contents } = useTypedSelector((state) => state.contentReducer)
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)

    const content = useMemo(() => {
        return contents.find(
            (c) => c.conceptId === currentConcept?._id && c.type === 'notice'
        )
    }, [contents, currentConcept])
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Baker Street доставка еды в Красноярске.
                    Завтраки, обеды и ужины с доставкой по всему городу, концерты, семейные торжества и корпоративные праздники!'
                />
                <title>{activeCategory.name}</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='menu-page'>
                <MenuHeader />
                {openBanner && content && (
                    <div className='menu-page__error-info'>
                        {content.text}
                        <div
                            className='menu-page__error-info--close'
                            onClick={() => setOpenBanner(false)}>
                            <img src={Close} alt='close' />
                        </div>
                    </div>
                )}
                {/* <Banner src={Ad} className='menu-page__ad' /> */}
                <BannersCarousel
                    slides={contents.filter(
                        (c) =>
                            c.conceptId === currentConcept?._id &&
                            c.type !== 'notice'
                    )}
                    // slides={[contents[0], contents[1], contents[2]]}
                />
                <Categories
                    active={activeElement}
                    restId={currentConcept?._id || ''}
                />
                {getCategories.loading && !categories ? null : isMobile ? (
                    <MenuItemsMobile
                        setActiveElement={setActiveElement}
                        restId={currentConcept?._id || ''}
                    />
                ) : (
                    <MenuItems restId={currentConcept?._id || ''} />
                )}

                <MiniCart />
            </div>
        </>
    )
}
