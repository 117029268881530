import { ICart, ICartModifiers } from '../interfaces/Cart'
import { IDeliveryCheck } from '../interfaces/Order'
import { IProduct } from '../interfaces/Product'

export const getTotal = (products: IProduct[], cart: ICart[], order?: IDeliveryCheck | null) => {
    let t = 0

    // if(products.length > 0) {
        cart.forEach(p => {
            const {product} = p
            const count = p.count
            const price = p.isPromocode ? 0 : getPrice(product.price, product.weight, p.modifiers) * count
            t += price
        })
    // }
    if(order && order.deliveryPrice) t += t > order.minOrderSum! ? 0 : order.deliveryPrice
    return t
}

interface IWeight {
    min: number
    full: number
}
export const getPrice = (price: number, weight: IWeight | null, options: ICartModifiers[], amount?: number) => {
    let total = price

    if(options.length > 0) {
        options.forEach(o => {
            o.option.forEach(op => total += op.price)
        })
    }
    if(weight)
        if(weight.min > 0) total *= weight.min

    if(amount) return Math.ceil(total * amount)
    return Math.ceil(total)
}