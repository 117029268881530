import React, { useEffect, useRef, useState } from 'react'
// import Background from '../../assets/background.jpg'
import AppleWallet from '../../assets/aw.svg'
import GoogleWallet from '../../assets/gw.svg'
import './Profile.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { useDispatch } from 'react-redux'
import { customerActions } from '../../store/reducers/customer'
import { PersonalInfo } from './PersonalInfo'
import { Orders } from './Orders'
import { Addresses } from './Addresses'
import { MiniCart } from '../../components/miniCart/MiniCart'
import { Helmet } from 'react-helmet'
const NavTypes = {
    profile: 'Профиль',
    orders: 'Заказы',
    // addresses: 'Адреса',
}
export const Profile = () => {
    const navigate = useNavigate()
    const { customer, balance, wallet } = useTypedSelector((state) => state.customerReducer)
    const dispatch = useDispatch()
    const [activeType, setActiveType] = useState<'profile' | 'orders'>('profile')
    useEffect(() => {
        if (!window.localStorage.getItem('baker_token')) {
            navigate('/signin')
        }
    }, [])

    const logout = () => {
        localStorage.removeItem('baker_token')
        dispatch(customerActions.clear())
        navigate('/')
    }

    const menuClickHandler = (e: React.MouseEvent<HTMLLIElement>) => {
        const elems = document.querySelectorAll('li.profile__nav-item')
        const target = e.target as HTMLLIElement
        target.classList.add('--active')
        elems.forEach((elem) => {
            if (elem !== target) elem.classList.remove('--active')
        })
        const data = target.dataset.type as 'profile' | 'orders'
        setActiveType(data)
    }
    if (!customer?._id) return <Placeholder />
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Baker Street доставка еды в Красноярске.
                    Завтраки, обеды и ужины с доставкой по всему городу, концерты, семейные торжества и корпоративные праздники!'
                />
                <title>Личный кабинет</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <section className='profile'>
                <div className='profile__header'>
                    <div className='profile__header-top'>
                        <h1 className='profile__header--title'>Личный кабинет</h1>
                        <ul className='profile__header--nav'>
                            <li
                                data-type='profile'
                                className='profile__nav-item --active'
                                onClick={menuClickHandler}>
                                Профиль
                            </li>
                            <li
                                data-type='orders'
                                className='profile__nav-item'
                                onClick={menuClickHandler}>
                                Заказы
                            </li>
                            <li className='profile__nav-item logout' onClick={logout}>
                                Выход
                            </li>
                        </ul>
                    </div>
                    <div className='profile__header-bonuses'>
                        Бонусы<span>{balance.total}</span>
                    </div>
                    <div className='profile__header-wallet'>
                        <a
                            href={`https://pay.google.com/gp/v/save/${
                                wallet?.googleWallet || customer.googleWallet
                            }`}
                            target='_blank'>
                            <img src={GoogleWallet} />
                        </a>
                        <a href={wallet?.appleWallet || customer.wallet.card} target='_blank'>
                            <img src={AppleWallet} />
                        </a>
                    </div>
                </div>

                {/* {activeType === 'addresses' && <Addresses />} */}

                <MiniCart />
            </section>
            <section className='profile-info'>
                <div className='profile-info__inner'>
                    {activeType === 'profile' && (
                        <PersonalInfo
                            fio={`${customer.firstName} ${customer.lastName}`}
                            phone={customer.phone}
                            dob={customer.dob}
                        />
                    )}
                    {activeType === 'orders' && <Orders />}
                </div>
            </section>
        </>
    )
}

// {activeType === 'profile' && (
//     <PersonalInfo
//         fio={`${customer.firstName} ${customer.lastName}`}
//         phone={customer.phone}
//         dob={customer.dob}
//     />
// )}
// {activeType === 'orders' && <Orders />}
