import React, { FC, useEffect, useRef, useState } from 'react'
import { IModifier, IModifierOption, IProduct } from '../../interfaces/Product'
import { getWeight } from '../../utils/getWeight'
import './Product.scss'
import Placeholder from '../../assets/zag.png'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store/store'
import { ICart, ICartModifiers } from '../../interfaces/Cart'
import { createId } from '../../utils/createId'
import { cartActions } from '../../store/reducers/cart'
import { ProductsCarousel } from '../../components/productsCarousel/ProductsCarousel'
import { useImage } from '../../hooks/useImage'
import { ProductInCart } from '../../components/productInCart/ProductInCart'
import { Link, useParams } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { MiniCart } from '../../components/miniCart/MiniCart'
import { conceptActions } from '../../store/reducers/concept'
import { Placeholder as Loader } from '../../components/placeholder/Placeholder'
import { useSaveCartToLocalStorage } from '../../hooks/useSaveCartToLocalStorage'
import { Helmet } from 'react-helmet'
import { ProductService } from '../../api/ProductService'
export const Product = () => {
    const [product, setProduct] = useState<IProduct>()
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const { products } = useTypedSelector((state) => state.productReducer)
    const { currentConcept, concepts } = useTypedSelector((state) => state.conceptReducer)
    const [options, setOptions] = useState<ICartModifiers[]>([])
    const [productsInCart, setProductsInCart] = useState<ICart[]>([])

    const { product_id, slug } = useParams()
    const { getProducts } = useActions()

    const btnRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        let isMount = true
        const getProduct = async (_slug: string) => {
            try {
                const api = new ProductService()
                const response = await api.getProductBySlug(_slug)
                if (isMount) setProduct(response)
            } catch (e) {
                console.log(e)
            }
        }
        if (currentConcept && product_id) {
            getProduct(product_id)
            // getProducts(currentConcept._id)
        }

        return () => {
            isMount = false
        }
    }, [currentConcept])
    useEffect(() => {
        if (!currentConcept && concepts.length > 0) {
            dispatch(conceptActions.setCurrentConcept(concepts.find((c) => c._id === slug)!))
        }
    }, [concepts])

    useEffect(() => {
        if (product_id) {
            const _p = products.find((p) => p.slug === product_id)
            if (_p) setProduct(_p)
        }
    }, [products])
    useEffect(() => {
        if (product) {
            const _newMods: ICartModifiers[] = product.modifiers.map((m) => {
                return {
                    name: m.name,
                    modifierId: m._id,
                    option: [m.options[0]],
                }
            })
            setOptions(_newMods)
            setTimeout(() => {
                showProducts()
            }, 500)
        }
    }, [product])
    useEffect(() => {
        showProducts()
    }, [cart])
    const isSave = useSaveCartToLocalStorage()
    const dispatch = useDispatch<AppDispatch>()
    const addToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        let isError = false
        if (!isSave) return alert('Чтобы продолжить необходимо очистить корзину')
        if (product) {
            options.forEach((o) => {
                const _group = product.modifiers.find((p) => p._id === o.modifierId)
                if (_group && _group.minAmount > o.option.length) {
                    isError = true
                    return alert(
                        `Необходимо выбрать минимум ${_group.minAmount} модификатор(-а) в ${_group.name}`
                    )
                }
            })
            if (isError) return

            const target = e.target as HTMLButtonElement
            const _cart: ICart = {
                count: 1,
                product,
                modifiers: options,
                id: createId(product._id, options),
                price: product.price,
                name: product.name,
            }
            dispatch(cartActions.addToCart(_cart))
            target.style.display = 'none'
            const sib = target.nextSibling as HTMLElement
            sib.style.display = 'flex'
        }
    }
    const onChangeOption = (
        e: React.MouseEvent<HTMLButtonElement>,
        group: IModifier,
        option: IModifierOption
    ) => {
        e.preventDefault()
        const ch = document.body.querySelectorAll(
            `button.product-modifiers__options-option[data-group='${group._id}']`
        )
        console.log(group)

        const target = e.target as HTMLButtonElement
        if (btnRef.current) {
            btnRef.current.style.display = 'block'
            const sib = btnRef.current.nextSibling as HTMLElement
            sib.style.display = 'none'
        }
        if (group.maxAmount === 1) {
            ch.forEach((el) => el.classList.remove('--choosen'))
            const _newMods: ICartModifiers[] = options.map((o) => {
                if (o.modifierId === group._id) {
                    return {
                        ...o,
                        option: [option],
                    }
                } else return o
            })
            target.classList.toggle('--choosen')
            return setOptions(_newMods)
        }

        const _opt = options.find((o) => o.modifierId === group._id)

        const optionInOptions = _opt?.option.find((op) => op._id === option._id)

        if (optionInOptions) {
            const _newMods: ICartModifiers[] = options.map((o) => {
                if (o.modifierId === group._id) {
                    return {
                        ...o,
                        option: o.option.filter((op) => op._id !== optionInOptions._id),
                    }
                } else return o
            })
            setOptions(_newMods)
            return target.classList.toggle('--choosen')
        }

        if (_opt?.option.length === group.maxAmount && group.maxAmount > 1) {
            return alert(`Нельзя добавить больше ${group.maxAmount} модификатора(-ов)`)
        }

        const _newMods: ICartModifiers[] = options.map((o) => {
            if (o.modifierId === group._id) {
                return {
                    ...o,
                    option: [...o.option, option],
                }
            } else return o
        })
        target.classList.toggle('--choosen')
        setOptions(_newMods)
    }
    const getCount = (id: string) => {
        const pInCart = cart.filter((p) => p.product._id === id)
        let totalCount = 0
        pInCart.forEach((p) => (totalCount += p.count))
        return totalCount
    }
    const imgRef = useRef<HTMLDivElement>(null)
    const { imgSrc } = useImage(product?.image?.body || '', Placeholder)
    const { activeCategory } = useTypedSelector((state) => state.productReducer)
    const showProducts = () => {
        if (product) {
            // let _p = products.find((p) => p._id === product._id)
            // if(!_p) {
            //     const api = new ProductService()
            //     _p = await api.getProductById(product._id)
            // }
            const _products = cart.filter((pr) => pr.product._id === product._id)
            setProductsInCart(_products)
            if (imgRef.current) {
                if (_products.length > 0) imgRef.current.classList.add('--has-items')
                else imgRef.current.classList.remove('--has-items')
            }
        }
    }
    if (!product) return <Loader />
    return (
        <>
            <Helmet>
                <meta name='description' content={product.description} />
                <title>{product.name}</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='modal-product'>
                <Link className='modal-product__back' to={`/${slug}/menu/${activeCategory.slug}`}>
                    <b>ᐸ</b> Назад
                </Link>
                <div className='modal-product__info'>
                    <div className='modal-product__info-img' ref={imgRef}>
                        <div className='modal-product__info-img--items'>
                            {productsInCart.map((p) => (
                                <ProductInCart key={p.id} product={product} productInCart={p} />
                            ))}
                        </div>
                        <div className='modal-product__info--badges'>
                            {product.badges?.map((b) => (
                                <div key={b._id} className='modal-product__info--badges-badge'>
                                    <img
                                        className='modal-product__info--badges-badge-img'
                                        src={b.image.body || ''}
                                        alt={b.name}
                                    />
                                    <div className='modal-product__info--badges-badge-name'>
                                        {b.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <img src={imgSrc} alt={product.name} />
                    </div>
                    <div className='modal-product__info-content'>
                        <div className='modal-product__info-content--header'>{product.name}</div>
                        <div className='modal-product__info-content--description'>
                            {product.description}
                        </div>
                        <div className='modal-product__info-content--mods'>
                            {product.modifiers &&
                                product.modifiers.map((m) => (
                                    <div className='product-modifiers' key={m._id}>
                                        <h2>{m.name}</h2>
                                        <div className='product-modifiers__options'>
                                            {m.options.map((o, i) => (
                                                <button
                                                    key={o._id}
                                                    data-group={m._id}
                                                    onClick={(e) => onChangeOption(e, m, o)}
                                                    className={`product-modifiers__options-option${
                                                        i === 0 ? ' --choosen' : ''
                                                    }`}>
                                                    {`${o.name}: ${o.price}₽`}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {/* <div className='modal-product__info-content--energy-value'>
                        Энергетическая ценность за порцию:{' '}
                        <span>
                            {Math.round(product.nutrition?.energy || 0)}Ккал
                        </span>
                    </div> */}
                        <div className='modal-product__info-content--weight'>
                            {getWeight(
                                product.weight?.min || 0,
                                product.weight?.full || 0,
                                product.unit
                            )}
                            <div className='modal-product__info-content--price'>
                                {product.price} ₽
                            </div>
                        </div>

                        {/* <div className='modal-product__info-content--nutrition'>
                        Основные питательные вещества:{' '}
                        <span>
                            белки: {Math.round(product.nutrition?.fiber || 0)},
                            жиры: {Math.round(product.nutrition?.fat || 0)},
                            углеводы:{' '}
                            {Math.round(product.nutrition?.carbohydrate || 0)}
                        </span>
                    </div> */}

                        <button
                            className='modal-product__info-content--add-to-cart'
                            style={{
                                display: getCount(product._id) > 0 ? 'none' : 'block',
                            }}
                            onClick={addToCart}
                            ref={btnRef}>
                            Добавить
                        </button>
                        <div
                            style={{
                                display: getCount(product._id) > 0 ? 'flex' : 'none',
                            }}
                            className='modal-product__info-content--counter'>
                            <button
                                onClick={() => dispatch(cartActions.decreaseCount([product, []]))}>
                                -
                            </button>
                            <span>{getCount(product._id)}</span>
                            <button
                                onClick={() => dispatch(cartActions.increaseCount([product, []]))}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <ProductsCarousel products={product.featured} />
                <MiniCart />
            </div>
        </>
    )
}
