import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store/store'
import { Provider } from 'react-redux'
import { hydrate, render } from 'react-dom'

import { YMInitializer } from 'react-yandex-metrika'

import './assets/fonts/Circe-Bold.ttf'
import './assets/fonts/Circe-ExtraBold.ttf'
import './assets/fonts/Circe-Light.ttf'
import './assets/fonts/Circe-Regular.ttf'

declare global {
    interface Window {
        dataLayer: Array<any>
    }
}

window.dataLayer = window.dataLayer || []

const Application = (
    <Provider store={store}>
        <BrowserRouter>
            <YMInitializer
                accounts={[58260999]}
                options={{
                    webvisor: true,
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    trackHash: true,
                    ecommerce: 'dataLayer',
                }}
                version='2'
            />
            <App />
        </BrowserRouter>
    </Provider>
)
// const rootElement = document.getElementById('root')
// if (rootElement?.hasChildNodes()) {
//     hydrate(Application, rootElement)
// } else {
//     render(Application, rootElement)
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(Application)
