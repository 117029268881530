import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logobs.png'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { DeliveryAddressInput } from '../UI/deliveryAddressInput/DeliveryAddressInput'
import './Header.scss'
export const Header = () => {
    const [isHidden, setHidden] = useState(true)
    const ddStyles = classNames({
        'delivery-address-search__dropdown--back': true,
        open: !isHidden,
    })
    const { customer, balance } = useTypedSelector((state) => state.customerReducer)
    return (
        <>
            <header className='header'>
                <div className='header__inner'>
                    <Link className='header__logo' to='/'>
                        <img className='header__logo' src={Logo} alt='logo' />
                    </Link>
                    {/* <DeliveryAddressInput
                    isHidden={isHidden}
                    setHidden={setHidden}
                /> */}
                    {customer ? (
                        <Link className='header__username' to={'/profile'}>
                            {`${customer.firstName} ${customer.lastName[0]}. (${balance.total} Б)`}
                        </Link>
                    ) : (
                        <Link className='header__signin' to={'/signin'}>
                            Войти
                        </Link>
                    )}
                </div>
            </header>
            <div className={ddStyles}></div>
        </>
    )
}
