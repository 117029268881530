import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICustomer, INewBalance, IWallet } from '../../interfaces/Customer'
import { getBalance, getProfile } from '../actions/customer'

interface CustomerState {
    customer: ICustomer | null
    balance: INewBalance
    wallet: IWallet | null
}

const initialState: CustomerState = {
    customer: null,
    balance: {
        balances: [],
        total: 0,
        categories: []
    },
    wallet: null
}

export const customerSlice = createSlice({
    initialState,
    name: 'customer',
    reducers: {
        clear: (state) => {
            state.customer = null
        }
    },
    extraReducers: {
        [getProfile.fulfilled.type]: (state, action: PayloadAction<ICustomer>) => {
            state.customer = action.payload
        },
        [getProfile.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.customer = null
            console.error(action.error.message)
            
        },
        [getBalance.fulfilled.type]: (state, action: PayloadAction<[string, INewBalance] | [string, IWallet]>) => {
            if(action.payload[0] === 'wallet') {
                state.wallet = action.payload[1] as IWallet
                state.balance.total = (action.payload[1] as IWallet).balance
            } else {
                state.balance = action.payload[1] as INewBalance
            }
           
        },
        [getBalance.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.balance = {
                balances: [],
                total: 0,
                categories: []
            }
            state.wallet = null
            console.error(action.error.message)
            
        }
    }
})

export const customerReducer = customerSlice.reducer
export const customerActions = customerSlice.actions