import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ConceptSevice } from '../../api/ConceptService'
import Ad from '../../assets/Banner.png'
import { Banner } from '../../components/banner/Banner'
import { BannersCarousel } from '../../components/bannersCarousel/BannersCarousel'
import { DeliveryCard } from '../../components/deliveryCard/DeliveryCard'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IConcept } from '../../interfaces/Concept'
import { conceptActions } from '../../store/reducers/concept'
import { productActions } from '../../store/reducers/products'
import './Main.scss'
import '../menu/Menu.scss'
import Close from '../../assets/crossBaker.png'
import { Helmet } from 'react-helmet'
export const Main = () => {
    const [concepts, setConcepts] = useState<IConcept[]>([])
    const { contents } = useTypedSelector((state) => state.contentReducer)
    const dispatch = useDispatch()
    useEffect(() => {
        let isMount = true

        const getConcepts = async () => {
            const api = new ConceptSevice()
            try {
                const _concepts = await api.getConcepts()
                if (isMount) setConcepts(_concepts!.data)
            } catch (e) {
                console.error(e)
            }
        }
        getConcepts()
        dispatch(conceptActions.setCurrentConcept(null))
        dispatch(productActions.setActiveCategory(['', '', '']))
        return () => {
            isMount = false
        }
    }, [])
    const [openBanner, setOpenBanner] = useState(true)

    const content = useMemo(() => {
        return contents.find((c) => c.conceptId === null && c.type === 'notice')
    }, [contents])
    if (concepts.length === 0) return <Placeholder />
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Baker Street доставка еды в Красноярске.
                    Завтраки, обеды и ужины с доставкой по всему городу, концерты, семейные торжества и корпоративные праздники!'
                />
                <title>Главная</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='main-page'>
                {/* <Banner src={content.image} className='main-page__ad' /> */}
                {contents.length > 0 && (
                    <BannersCarousel
                        slides={contents.filter(
                            (c) => c.conceptId === null && c.type !== 'notice'
                        )}
                        className='main-page__ad'
                        slideToShow={1}
                        isMain
                    />
                )}
                {openBanner && content && (
                    <div className='menu-page__error-info'>
                        {content.text}
                        <div
                            className='menu-page__error-info--close'
                            onClick={() => setOpenBanner(false)}>
                            <img src={Close} alt='close' />
                        </div>
                    </div>
                )}
                <div className='main-page__delivery-cards'>
                    {concepts &&
                        concepts
                            // .filter((c) => c._id !== '634696850132804c5b878640')
                            .map((m) => (
                                <DeliveryCard key={m._id} concept={m} />
                            ))}
                </div>
            </div>
        </>
    )
}
