import React, { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Pin from '../../assets/pin_brown.png'
import Phone from '../../assets/phone_brown.png'
import Time from '../../assets/time_brown.png'
import Wallet from '../../assets/wallet_brown.png'
import './MenuHeader.scss'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store/store'
import { ConceptSevice } from '../../api/ConceptService'
import { customerActions } from '../../store/reducers/customer'
import { IConcept } from '../../interfaces/Concept'
import { useActions } from '../../hooks/useActions'
import { isMobile } from 'react-device-detect'
import Arrow from '../../assets/arrow-top.png'
export const MenuHeader = () => {
    const { slug } = useParams()
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const [isHide, setHide] = useState(true)
    const dispatch = useDispatch<AppDispatch>()
    const { getConcepts } = useActions()
    // useEffect(() => {
    //     if (!currentConcept && slug) {
    //         getConcepts(slug)
    //     }
    // }, [])
    const hideInfo = () => {
        if (isMobile) {
            setHide((prev) => !prev)
        }
    }
    return (
        <div
            className={`menu-header ${isHide ? '--hidden' : ''} ${
                isMobile ? '--mobile' : ''
            }`}
            onClick={hideInfo}>
            <div className='menu-header__inner'>
                <h1 className='menu-header__name'>{currentConcept?.name}</h1>
                {isMobile && isHide ? null : (
                    <div className='menu-header__info'>
                        <div className='menu-header__info--address'>
                            <div className='menu-header__info--icon'>
                                <img
                                    src={Pin}
                                    alt={
                                        currentConcept?.additionalData?.address
                                    }
                                />
                            </div>
                            <p className='menu-header__info--text'>
                                {currentConcept?.additionalData?.address}
                            </p>
                        </div>
                        <div className='menu-header__info--phone'>
                            <div className='menu-header__info--icon'>
                                <img
                                    src={Phone}
                                    alt={currentConcept?.additionalData?.phone}
                                />
                            </div>
                            <p className='menu-header__info--text'>
                                <a
                                    href={`tel:${currentConcept?.additionalData?.phone}`}>
                                    {currentConcept?.additionalData?.phone}
                                </a>
                            </p>
                        </div>
                        <div className='menu-header__info--schedule'>
                            <div className='menu-header__info--icon'>
                                <img
                                    src={Time}
                                    alt={currentConcept?.additionalData?.businessHours.toString()}
                                />
                            </div>
                            <div>
                                {currentConcept?.additionalData?.businessHours.map(
                                    (h, i) => (
                                        <p
                                            key={i}
                                            className='menu-header__info--text'>
                                            {h.days}: {h.hours}
                                        </p>
                                    )
                                )}
                            </div>
                        </div>
                        <div className='menu-header__info--price'>
                            <div className='menu-header__info--icon'>
                                <img
                                    src={Wallet}
                                    alt={currentConcept?.additionalData?.minDeliveryCost.toString()}
                                />
                            </div>
                            <p className='menu-header__info--text'>
                                от{' '}
                                {
                                    currentConcept?.additionalData
                                        ?.minDeliveryCost
                                }
                                ₽
                            </p>
                        </div>
                    </div>
                )}
            </div>
            {isMobile && (
                <div className='menu-header__arrow'>
                    <img src={Arrow} alt='arrow' />
                </div>
            )}
        </div>
    )
}
