import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IConcept } from '../../interfaces/Concept'
import { getConceptById, getConcepts } from '../actions/concept'

interface ConceptState {
    currentConcept: IConcept | null
    concepts: IConcept[]
}

const initialState: ConceptState = {
    currentConcept: null,
    concepts: []
}

const conceptSlice = createSlice({
    name: 'concept',
    initialState,
    reducers: {
        setCurrentConcept: (state, action: PayloadAction<IConcept | null>) => {
            state.currentConcept = action.payload
        }
    },
    extraReducers: {
        [getConceptById.fulfilled.type]: (state, action: PayloadAction<IConcept>) => {
            state.currentConcept = action.payload
        },
        [getConceptById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            console.error(action.error.message)
        },
        [getConcepts.fulfilled.type]: (state, action: PayloadAction<[IApi<IConcept>, string?]>) => {
            state.concepts = action.payload[0].data
            const slug = action.payload[1]          
            if(slug) state.currentConcept = action.payload[0].data.find(c => c.slug === slug) || null
        },
        [getConcepts.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            console.error(action.error.message)
        },
    }
})


export const conceptReducer = conceptSlice.reducer
export const conceptActions = conceptSlice.actions