import axios from 'axios'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(async req => {
    if(req.headers) {
        req.headers['Company-Access-Key'] = 'x3fPPoim5uycw7ytq09Fqwu0f3m895dw8376tdqux02CZc9qh73t6y38tg7'
        req.headers['Loyalaty-Id'] = '646d9d886ab8b97e6c7d8a4d'
    }
    return req
})