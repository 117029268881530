import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { CartProduct } from '../../components/cartProduct/CartProduct'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { cartActions } from '../../store/reducers/cart'
import { orderActions } from '../../store/reducers/order'
import { getTotal } from '../../utils/getTotal'
import './ThanksForOrder.scss'
import Cutlery from '../../assets/cutlery.png'
import { Helmet } from 'react-helmet'
import { IProduct } from '../../interfaces/Product'
import { ProductService } from '../../api/ProductService'

export const ThanksForOrder = () => {
    const { cart, cutlery } = useTypedSelector((state) => state.cartReducer)
    // const { products } = useTypedSelector((state) => state.productReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [orderProducts, setOrderProducts] = useState<IProduct[]>([])

    useEffect(() => {
        let isMount = true
        const loadMenu = async () => {
            try {
                const api = new ProductService()
                const promisesProducts = cart.map((c) => api.getProductById(c.product._id))
                const _products = await Promise.all(promisesProducts)
                if (isMount) {
                    setOrderProducts(_products)
                }
            } catch (e) {
                console.log(e)
            }
        }
        loadMenu()
        return () => {
            isMount = false
            dispatch(orderActions.clearOrder())
            dispatch(cartActions.clear())
        }
    }, [])
    if (cart.length === 0) return <Navigate to={'/'} />
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Baker Street доставка еды в Красноярске.
                    Завтраки, обеды и ужины с доставкой по всему городу, концерты, семейные торжества и корпоративные праздники!'
                />
                <title>Спасибо за Ваш заказ!</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='thanks-for-order'>
                <h1>Спасибо за Ваш заказ!</h1>
                <p>Мы уже обрабатываем ваш заказ</p>
                <div className='thanks-for-order__cart'>
                    {orderProducts.map((orderProduct, i) => {
                        const _productInCart = cart.find((p) => p.product._id === orderProduct._id)
                        return _productInCart ? (
                            <CartProduct
                                key={`${orderProduct._id} ${i}`}
                                product={orderProduct}
                                productInCart={_productInCart}
                                readOnly
                                readOnlyLeft
                            />
                        ) : null
                    })}
                    {cutlery.amount > 0 && (
                        <div className='cart-item'>
                            <img className='cart-item__image' src={Cutlery} alt={'Cutlery'} />
                            <div className='cart-item__name'>
                                <p>Приборы</p>
                                <small>Вилка, нож</small>
                            </div>
                            <div className='cart-item__counter'>
                                <span>{cutlery.amount} шт.</span>
                            </div>
                            <div className='cart-item__price'>0₽</div>
                        </div>
                    )}
                </div>
                <div className='thanks-for-order__total'>
                    <span>Итого</span>
                    <span>{getTotal(orderProducts, cart)}₽</span>
                </div>
                <button className='thanks-for-order__back' onClick={() => navigate('/')}>
                    На главную
                </button>
            </div>
        </>
    )
}
