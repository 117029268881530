import { createAsyncThunk } from '@reduxjs/toolkit'
import { CustomerSevice } from '../../api/CustomerService'
import { ICustomer } from '../../interfaces/Customer'

export const getProfile = createAsyncThunk('customer/getProfile', async() => {
    const api = new CustomerSevice()
    const profile = await api.profile() as ICustomer
    return profile
})

export const getBalance = createAsyncThunk('customer/getBalance', async() => {
    const api = new CustomerSevice()
    const balance = await api.getBalance()
    return balance
})