import React, { FC, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { CategorySevice } from '../../api/CategoriesService'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ICategory } from '../../interfaces/Category'
import { productActions } from '../../store/reducers/products'
import { AppDispatch } from '../../store/store'
import { CategoriesCarousel } from '../categoriesCarousel/CategoriesCarousel'
import { Placeholder } from '../placeholder/Placeholder'
import './Categories.scss'

interface CategoriesProps {
    restId: string
    active: string
}
export const Categories: FC<CategoriesProps> = ({ restId, active }) => {
    const {
        activeCategory,
        activeSubCategory,
        categories,
        getCategories: getCategoriesReducer,
    } = useTypedSelector((state) => state.productReducer)
    const { getCategories } = useActions()
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        if (currentConcept) getCategories(currentConcept._id)
    }, [currentConcept])

    if (categories.length === 0) return <Placeholder />
    if (isMobile) {
        return <CategoriesCarousel active={active} />
    }

    const setActiveCategory = (activeCat: ICategory) => {
        const subCat = categories.find((c) => c.parentCategory === activeCat._id)
        dispatch(productActions.setActiveCategory([activeCat._id, activeCat.name, activeCat.slug]))
        dispatch(productActions.setActiveSubCategory([subCat?._id || '', subCat?.name || '']))
    }
    return (
        <>
            <ul className='categories-list'>
                {categories.map((cat) => (
                    // cat.parentCategory ? null :
                    <li
                        key={cat._id}
                        className={`categories-list__item ${
                            activeCategory._id === cat._id ? 'active' : ''
                        }`}
                        onClick={() => setActiveCategory(cat)}>
                        {cat.name}
                    </li>
                ))}
            </ul>
            {/* <ul className='subcategories-list'>
                {categories.map(
                    (cat) =>
                        cat.parentCategory === activeCategory._id && (
                            <li
                                key={cat._id}
                                className={`subcategories-list__item ${
                                    activeSubCategory._id === cat._id ? 'active' : ''
                                }`}
                                onClick={() =>
                                    dispatch(
                                        productActions.setActiveSubCategory([cat._id, cat.name])
                                    )
                                }>
                                {cat.name}
                            </li>
                        )
                )}
            </ul> */}
        </>
    )
}
